import React from 'react';
// import "../../App.css";
import * as Icon from "react-bootstrap-icons"

const Offline = () => {

  return (
    <>
    <div style={{width:"100%",display:"flex", flexDirection:"column",justifyContent:"center",alignItems:"center", position:"relative"}}>
        {/* <div className='offStyle'> */}
            <Icon.WifiOff size={50} className='mb-3'/>
                <h4 style={{textAlign:"center"}}  className='mb-3'>Your Connection was Offline.</h4>
                <h6 style={{textAlign:"center"}}>Please check your Wifi Connection.</h6>
        {/* </div> */}
        
    </div>
      
    </>
  )
}

export default Offline

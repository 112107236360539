import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import QrReader from "react-web-qr-reader";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import Scan from "../../Assets/Vector (1).png";
import axios from "axios";
import Offline from "../Common/Offline";

const Scanner = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [loader, setLoader] = useState(false)
  const [showAlreadyConfirmed, setShowAlreadyConfirmed] = useState(false)
  const [successfullyVerified, setSuccessfullyVerified] = useState(false)

  const NotRegistered = () =>
    toast.error("Sorry, This user is not registered for event");
  const SuccessRegistered = () => toast.success("Participant Confirmed!");

  const InvalidQR = () => toast.error("Scanned QR is Invalid")

  const [showUUIDdetails, setShowUUIDdetails] = useState(false)
  const [showQrDetails, setShowQrDetails] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [modalData, setModalData] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [uuidData, setUuidData] = useState("")

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
 
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
 
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  const handleScan = async(data) => {
    try {
      let val = JSON.parse(data.data);
      console.log("val",val);
      scanQR(val.uid)
    } catch (e) {
      InvalidQR()
    }
  };

  const scanQR = async (uuid) => {
    try {
      const response = await axios.post("https://api.sfg.foxberry.live/v1/event/scanQr", {"UUId": uniqueId ? uniqueId : uuid});
      if (response) {
        if (response.data.messsage === 'Already Availed') {
          // setShowAlreadyConfirmed(true)

          setShowQrDetails(true);
          setUuidData(response.data.data);
          setShowUUIDdetails(true)
        } else if (response.data.messsage === 'Verified successfully') {
          setShowQrDetails(true);
          setUuidData(response.data.data);
          setShowUUIDdetails(true)
        } else if (response.data.error === "Somthing Went Wrong"){
          toast.error("Your scanner is invalid OR You entered wrong UUID, Please check.");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
} 

  const handleEror = (err) => {
    console.log(err);
  };

  const handleEnterUniqueId = () => {
    if (uniqueId.trim() !== "") {
      scanQR()
    } else {
      toast.error("Please enter a unique UUID.");
    }
  };

  const previewStyle = {
    height: 300,
    width: 300,
    display: "flex",
    justifyContent: "center",
  };

  const camStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const ConfirmEventAttendance = async () => {
    setShowQrDetails(false)
    setIsScanning(false)
    setShowUUIDdetails(false)
    toast.success("successfully fetch data")
    setUniqueId("")
  }

  const [isScanning, setIsScanning] = useState(false);

  const eventBookingDate = (bookingDate) => {
    const dateObject = new Date(bookingDate);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const formatted = dateObject.toLocaleString('en-US', options);
    return formatted
  }
  return (
    <>
    {
      isOnline ? 
      <>
      <ToastContainer />
      <div style={{height:"100vh", display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
        {!isScanning ? (
          <>
            <Row style={{justifyContent: "center", padding:"15%",}}>
            <Col xs={12} md={12} lg={12} className="mb-3">
              <h1 style={{textAlign:"center"}}>SCANNER</h1>
            </Col>

              <Col xs={12} md={12} lg={6}>
                <button onClick={() => setIsScanning(true)}
                  style={{
                    width:"100%",fontSize: "16px",fontWeight: 400, lineHeight: "19.2px", textAlign: "center",  height: "58px",
                    color: "#FD6E47", backgroundColor: "transparent", border: "1px solid #FD6E47", borderRadius: "10px"
                  }}>
                  <img src={Scan} style={{ marginRight: "15px", width: "24px", height: "24px" }} />
                  <span>Scan QR Code</span>
                </button>
              </Col>

              <Col xs={12} md={12} lg={12} className="mt-3">
                <p style={{ textAlign: "center", fontSize: "18px", fontWeight: 400 }}>Or</p>
              </Col>

              <Col xs={12} md={12} lg={6}>
                <Form.Control type="text" placeholder="Enter UUID" style={{ border: "1px solid #FD6E47", borderRadius: "10px" }}
                  name="uuid" value={uniqueId.toUpperCase()}
                  onChange={(e) => {
                    setUniqueId(e.target.value.toUpperCase())
                  }}
                />
              </Col>

              <Col xs={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                {loader ?
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spinner size='lg' />
                  </div> :
                  <button style={{ backgroundColor: "#FD6E47", color: "white", border: "none", borderRadius: "10px", padding: "5px 15px" }}
                    onClick={() => handleEnterUniqueId()}>Submit</button>
                }
              </Col>

            </Row>

            {/* <Row style={{ justifyContent: "center" }}>
              <Col xs={12} md={12} lg={6} className="mt-3">
                <p style={{ textAlign: "center", fontSize: "18px", fontWeight: 400 }}>Or</p>
              </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
              <Col xs={12} md={12} lg={6}>
                <Form.Control type="text" placeholder="Enter UUID" style={{ border: "1px solid #FD6E47", borderRadius: "10px" }}
                  name="uuid" value={uniqueId.toUpperCase()}
                  onChange={(e) => {
                    setUniqueId(e.target.value.toUpperCase())
                  }}
                />
              </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
              <Col xs={12} md={12} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
                {loader ?
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spinner size='lg' />
                  </div> :
                  <button style={{ backgroundColor: "#FD6E47", color: "white", border: "none", borderRadius: "10px", padding: "5px" }}
                    onClick={() => handleEnterUniqueId()}>Submit</button>
                }
              </Col>
            </Row> */}
          </>
        ) : (
          <>
            <div style={{ marginBottom: "15px" }}>
              <button onClick={() => { setIsScanning(false) }}
                style={{ backgroundColor: "#FD6E47", color: "white", border: "none",  borderRadius: "10px", padding: "5px 15px" }}>
                <Icon.ArrowLeft className="me-2" />Back
              </button>
            </div>
            <div style={camStyle}>
              <QrReader
                style={previewStyle}
                onError={handleEror}
                onScan={handleScan}
                facingMode="environment"
              />
            </div>
          </>
        )}
      </div>

      {/* UUID Data */}
      <Modal
        show={showUUIDdetails}
        onHide={() => { setShowUUIDdetails(false); setUniqueId("") }}
        size="md"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Scanned Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uuidData.bookedSlot !== undefined && uuidData.bookedSlot !== null && uuidData.bookedSlot.length > 0 ?
          uuidData.bookedSlot.map((val, index) =>{
            return (
              <> 
                {
                  val.name ? 
                  <p className="mb-3">
                      <Icon.Person className="me-2" />
                      Candidate Name : <span style={{fontWeight:600}}>{val.name}</span> 
                    </p> :""
                }
                   
                {
                  val.date ?
                  <p className="mb-3">
                      <Icon.Calendar className="me-2" />
                      Booking Date : <span style={{fontWeight:600}}>{val.date}</span> 
                    </p> : ""
                }

                {
                  val.slots ? 
                  <p className="mb-3">
                      <Icon.Stopwatch className="me-2" />
                      Booking Slot : <span style={{fontWeight:600}}>{val.slots}</span>
                    </p> : ""
                }
              </>
            )
          }) : "No Data Found"
        }
        {
          uuidData.familyMembersCount ? 
          <p className="mb-3">
            <Icon.People className="me-2" />
            Family Member : <span style={{fontWeight:600}}>{uuidData.familyMembersCount ? uuidData.familyMembersCount : 0}</span>
          </p> : ""
        }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUUIDdetails(false)}>
            Cancel
          </Button>
          <Button style={{ backgroundColor: "#FD6E47", color: "white", border: "none" }} onClick={ConfirmEventAttendance}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal> 
      
      {/* Already confirmed model */}
      <Modal
        show={showAlreadyConfirmed}
        onHide={() => {
          setShowAlreadyConfirmed(false);
        }}
        size="md"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Scanned Data
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5>User Already Availed.</h5>
          {uuidData.bookedSlot !== undefined && uuidData.bookedSlot !== null && uuidData.bookedSlot.length > 0 ?
          uuidData.bookedSlot.map((val, index) =>{
            return (
              <> 
                {
                  val.name ? 
                  <p className="mb-3">
                      <Icon.Person className="me-2" />
                      Candidate Name : <span style={{fontWeight:600}}>{val.name}</span> 
                    </p> :""
                }
                   
                {
                  val.date ?
                  <p className="mb-3">
                      <Icon.Calendar className="me-2" />
                      Booking Date : <span style={{fontWeight:600}}>{val.date}</span> 
                    </p> : ""
                }

                {
                  val.slots ? 
                  <p className="mb-3">
                      <Icon.Stopwatch className="me-2" />
                      Booking Slot : <span style={{fontWeight:600}}>{val.slots}</span>
                    </p> : ""
                }
              </>
            )
          }) : "No Data Found"
        }
        {
          uuidData.familyMembersCount ? 
          <p className="mb-3">
            <Icon.People className="me-2" />
            Family Member : <span style={{fontWeight:600}}>{uuidData.familyMembersCount ? uuidData.familyMembersCount : 0}</span>
          </p> : ""
        }
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowAlreadyConfirmed(false);
              setShowQrDetails(false);
            }}
          >
            ok
          </Button>
        </Modal.Footer>

      </Modal>

      {/* successfully verified */}
      <Modal
        show={successfullyVerified}
        onHide={() => {
          setSuccessfullyVerified(false);
        }}
        size="md"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>

        <Modal.Body>
          <h3>User Verified successfully!</h3>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setSuccessfullyVerified(false);
              // setShowQrDetails(false);
            }}
          >
            ok
          </Button>
        </Modal.Footer>

      </Modal>
      </> 
      : <Offline/>
      }
     
    </>
  );
};

export default Scanner;
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import AddEvent from './Components/Admin/AddEvent';
import AdminHome from './Components/Admin/AdminHome';
import Login from './Components/Common/Login';
import CoordinatorHome from './Components/Coordinator/CoordinatorHome';
import Scanner from './Components/Coordinator/Scanner';
import ScannerList from './Components/Coordinator/ScannerList';
import AdminLayout from './Layout/AdminLayout';
import CoordinatorLayout from './Layout/CoordinatorLayout';
import AllEventList from './Components/Admin/AllEventList';
import RegisterList from './Components/Admin/RegisterList';
import EditEvent from './Components/Admin/EditEvent';
import EnterOtp from './Components/Admin/EnterOtp';
import EnterMobile from './Components/Admin/EnterMobile';
import AddMember from './Components/Admin/AddMember';
import AddCoordinator from './Components/Admin/AddCoordinator';

function App() {
  return (
    <BrowserRouter>
    <Routes>
    {/* <Route path="/" element={<Login />} /> */}

    {/* ---- Co-ordinator Routes ----- */}
    <Route path='/' element={<Scanner/>}/>
      {/* <Route path='/' element={<CoordinatorLayout/>}>
        <Route path='/' element={<Scanner/>}/>
        <Route path='/coordinator' element={<CoordinatorHome/>}/>
        
        <Route path='/coordinator/scanner-list' element={<ScannerList/>}/>
      </Route>  */}

      {/* <Route path='/admin' element={<AdminLayout/>}>
        <Route path='/admin' element={<AdminHome/>}/>
        <Route path='/admin/add-event' element={<AddEvent/>}/>
        <Route path='/admin/all-event-list' element={<AllEventList/>}/>

        <Route path='/admin/event-registration' element={<RegisterList/>}/>
        
        <Route path='/admin/add-coordinator' element={<AddCoordinator/>}/>
        <Route path='/admin/enter-mobile' element={<EnterMobile/>}/>
        <Route path='/admin/enter-otp' element={<EnterOtp/>}/>
        <Route path='/admin/add-member-attendance' element={<AddMember/>}/>

        
        <Route path='/admin/edit-event' element={<EditEvent/>}/>
      </Route>   */}
    </Routes>
    </BrowserRouter>
  );
}

export default App;
